body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(242, 242, 242, 1);
}

#root {
  background: rgba(242, 242, 242, 1);
}

.banner {
  width: 100%;
  height: 178px;
  background: url(./image/banner@1x.png);
  background-size: 100% 100%;
}

/* dpr比值为2的css代码 */
@media screen and (-webkit-device-pixel-ratio: 2) {
  .banner {
    background-image: url(./image/banner@2x.png);
  }
}

/*dpr比值为3的css代码 */
@media screen and (-webkit-device-pixel-ratio: 3) {
  .banner {
    background-image: url(./image/banner@3x.png);
  }
}

.label {
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 50px;
}

.required {
  color: rgba(255, 50, 87, 1);
}
.mr {
  color: rgba(0, 0, 0, 0.4);
}

.block {
  background: #fff;
  padding: 12px 26px;
  margin-bottom: 12px;
  position: relative;
}


.am-input-item {
  border-bottom: 1px solid #ddd;
}

.error {
  padding: 6px 0 0 0;
  display: block;
  font-size: 13px;
  color: #FF3257;
}

.message {
  padding: 12px 26px;
  background: #fff;
}

.message>.title {
  font-size: 21px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 50px;
}

.message>.desc {
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 26px;
}

.info .ant-radio-group {
  width: 100%;
}

.info .ant-radio-wrapper {
  display: block;
  height: 45px;
  line-height: 45px;
  width: 100%;
  border-bottom: 1px solid #ddd;
}


.info .am-list-item .am-list-line .am-list-extra {
  flex-basis: 100%;
  text-align: left;
}

.description {
  height: 40px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 40px;
  margin-top: -20px;
  padding-left: 12px;
}

.inline-radio span>div {
  display: inline-block;
  margin-left: -10px;
}

.ant-radio {
  margin-right: 10px;
}

.info .am-list-item {
  background-color: transparent;
  border-bottom: 1px solid #ddd;
  min-height: 40px;
}

.info .am-list-item .am-list-line .am-list-extra {
  color: #000
}

.info .ant-radio-wrapper-disabled .am-list-item .am-list-line .am-list-extra {
  color: #bbb
}
.info .block[disabled] .am-list-item .am-list-line .am-list-extra {
   color: #bbb
}

#root .spe {
  width: 70px;
  height: 70px;
}

#root .am-result .am-result-pic {
  margin: 120px auto 24px auto;
  height: 70px;
  width: 70px;
}

#root .am-result {
  height: 100vh;
}

#root .am-result .am-result-title {
  width: 80%;
  margin: 15px auto;
  line-height: 1.4;
}