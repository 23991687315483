.daily-banner {
    width: 100%;
    height: 178px;
    background: url(../image/daily/banner.png);
    background-size: 100% 100%;
}

/* dpr比值为2的css代码 */
@media screen and (-webkit-device-pixel-ratio: 2) {
    .daily-banner {
        background-image: url(../image/daily/banner@2x.png);
    }
}

/*dpr比值为3的css代码 */
@media screen and (-webkit-device-pixel-ratio: 3) {
    .daily-banner {
        background-image: url(../image/daily/banner@3x.png);
    }
}

.record {
    position: relative;
    margin: 26px 0;
}

.record .record-title {
    /* position: relative;
    position: absolute; */
    height: 1px;
    background-color: #ddd;
    overflow: visible;
    text-align: center;
}

.record .record-title>span {
    display: inline-block;
    padding: 0 12px;
    background-color: #fff;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    position: relative;
    top: -10px
}

.records {
    display: flex;
    flex-wrap: wrap;
}

.records .point {
    flex: 1;
    margin: 10px 5px;
    text-align: center;
    color: #D9EDFF;
    position: relative;
}

.records .point .anticon{
    font-size: 32px;
}

.records .point .circle {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background: #D9EDFF;
    border-radius: 16px ;
    margin: auto;
}
.records .point.now{
    color: #00A0FF;
}
.records .point.disabled{
    color: #ddd
}
.records .point.disabled .circle{
    background-color: #ddd;
}

.records .point.checked{
    color: #74BDFF;
}
.icon-ishow{
    position: absolute;
    right: 12px;
    top: 28px;
}